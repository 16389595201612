import { filter, isEmpty } from 'lodash';

const getData = (entity: string) => {
  if (process.browser) {
    const store = JSON.parse(localStorage.getItem('snowplow') || '{}');

    if (!store || !store[entity] || Object.keys(store[entity]).length === 0) {
      return {};
    }
    return store[entity];
  }
  return {};
};

const schemas = {
  bookmark: 'iglu:br.com.pebmed/bookmark/jsonschema/1-1-1',
  button_click: 'iglu:br.com.pebmed/button_click/jsonschema/4-0-37',
  subscribe: 'iglu:br.com.pebmed/subscribe/jsonschema/2-0-0',
  signup: 'iglu:br.com.pebmed/signup/jsonschema/2-0-0',
  signup_update: 'iglu:br.com.pebmed/signup_update/jsonschema/1-0-2',
  login: 'iglu:br.com.pebmed/login/jsonschema/3-0-1',
  search: 'iglu:br.com.pebmed/search/jsonschema/3-0-5',
  play: 'iglu:br.com.pebmed/play/jsonschema/2-0-0',
  popup_view: 'iglu:br.com.pebmed/popup_view/jsonschema/2-0-28',
  view: 'iglu:br.com.pebmed/view/jsonschema/1-0-2',
  view_time: 'iglu:br.com.pebmed/view_time/jsonschema/1-0-0',
  list_action: 'iglu:br.com.pebmed/list_action/jsonschema/4-0-2',
  feedback: 'iglu:br.com.pebmed/feedback/jsonschema/1-0-4',
  share: 'iglu:br.com.pebmed/share/jsonschema/2-0-1',
  rate: 'iglu:br.com.pebmed/rate/jsonschema/2-0-0',
};

export const getSchema = (schema: string) => schemas[schema];

export const getEntities = () => {
  const entities = [
    {
      schema: 'iglu:br.com.pebmed/user/jsonschema/2-0-2',
      data: getData('user'),
    },
    {
      schema: 'iglu:br.com.pebmed/category/jsonschema/2-0-2',
      data: getData('category'),
    },
    {
      schema: 'iglu:br.com.pebmed/experiment/jsonschema/1-0-0',
      data: getData('experiment'),
    },
    {
      schema: 'iglu:br.com.pebmed/topic/jsonschema/3-0-3',
      data: getData('topic'),
    },
  ];

  return filter(entities, (entity) => !isEmpty(entity.data));
};
