import { DiagnosisDiseaseModel } from '@models/diagnosis/disease-model';
import { DiagnosisSymptomModel } from '@models/diagnosis/symptom-model';

export enum DiagnosisStepEnum {
  PERSONAL = 1,
  SYMPTOMS = 2,
  RESULT = 3,
}

export type RegistrantDiagnosisModelType = {
  rating: boolean;
  step: DiagnosisStepEnum;
  gender: DiagnosisSymptomModel;
  age: DiagnosisSymptomModel;
  symptoms: DiagnosisSymptomModel[];
  results: DiagnosisDiseaseModel[];
  closeTastingBanner: boolean;
};
