import { DiagnosisActionTypes } from '@context/diagnosis/actions';
import { DiagnosisActionsEnum } from '@context/diagnosis/types';
import { DiagnosisStepEnum, RegistrantDiagnosisModelType } from '@models/diagnosis/registrant-diagnosis-model';

export const initialDiagnosisState: RegistrantDiagnosisModelType = {
  rating: false,
  step: DiagnosisStepEnum.PERSONAL,
  gender: null,
  age: null,
  symptoms: [],
  results: [],
  closeTastingBanner: false,
};

export const diagnosisReducer = (
  state: RegistrantDiagnosisModelType = initialDiagnosisState,
  action: DiagnosisActionTypes,
) => {
  switch (action.type) {
    case DiagnosisActionsEnum.SET_RATING:
      return { ...state, rating: action.rating };

    case DiagnosisActionsEnum.SET_STEP:
      return { ...state, step: action.step };

    case DiagnosisActionsEnum.SET_GENDER:
      return { ...state, gender: action.gender };

    case DiagnosisActionsEnum.SET_AGE:
      return { ...state, age: action.age };

    case DiagnosisActionsEnum.SET_SYMPTOMS:
      return { ...state, symptoms: action.symptoms };

    case DiagnosisActionsEnum.SET_RESULTS:
      return { ...state, results: action.results };

    case DiagnosisActionsEnum.SET_CLOSE_TASTING_BANNER:
      return { ...state, closeTastingBanner: action.closeTastingBanner };

    default:
      return state;
  }
};
