import CalculatorTastingFeatureModelType from '@models/load-calculator-tasting-feature';
import tastingFeatureModelType from '@models/load-tasting-feature';

export enum FeatureAndTastingEnum {
  SET_FEATURE = '@feature-tasting/SET_FEATURE',
  SET_TASTING = '@feature-tasting/SET_TASTING',
  SET_IS_LOADING = '@feature-tasting/SET_IS_LOADING',
}

export type FeatureAndTastingTypes = {
  feature: tastingFeatureModelType[];
  tasting: CalculatorTastingFeatureModelType[];
  isLoading: boolean;
};
