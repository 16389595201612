import { FeatureAndTastingActionTypes } from '@context/feature-tasting/actions';
import { FeatureAndTastingEnum } from '@context/feature-tasting/types';
import CalculatorTastingFeatureModelType from '@models/load-calculator-tasting-feature';
import tastingFeatureModelType from '@models/load-tasting-feature';

export const initialFeatureAndTastingState: {
  feature: tastingFeatureModelType[];
  tasting: CalculatorTastingFeatureModelType[];
  isLoading: boolean;
} = {
  feature: [],
  tasting: [],
  isLoading: true,
};

export const featureAndTastingReducer = (
  state = initialFeatureAndTastingState,
  action: FeatureAndTastingActionTypes,
) => {
  switch (action.type) {
    case FeatureAndTastingEnum.SET_FEATURE: {
      if (!action?.feature) {
        return state;
      }

      const hasFeatureInContext = state.feature.findIndex((feature) => feature.id === action.feature.id);

      if (hasFeatureInContext === -1) {
        state.feature.push(action.feature);
      }

      return state;
    }

    case FeatureAndTastingEnum.SET_TASTING: {
      if (!action?.tasting) {
        return state;
      }

      // @ts-ignore
      const hasTastingInContext = state.tasting.findIndex((tasting) => tasting.id === action.tasting.id);

      if (hasTastingInContext === -1) {
        state.tasting.push(action.tasting);
      }

      return state;
    }

    case FeatureAndTastingEnum.SET_IS_LOADING:
      return { ...state, isLoading: action.isLoading };

    default:
      return state;
  }
};
