import RemoteLoadSubscription, {
  RemoteLoadSubscription as RemoteLoadSubscriptionType,
} from '@remote-data/load-subscription';
import { CheckSubscription, StatusSubscription } from '@use-cases/check-subscription/types';
import { handleError } from '@use-cases/errors/error-handling';
import { SubscriptionModel } from '@remote-data/load-subscription/types';
import SubscriptionLocalStorage from '@local-storage/subscription';
import AuthLocalStorage from '@local-storage/auth';

export class UseCaseCheckSubscription {
  constructor(private readonly remoteLoadSubscription: RemoteLoadSubscriptionType) {}

  async run(): Promise<CheckSubscription> {
    try {
      const authToken = AuthLocalStorage.getToken();
      const { body: userSubscription } = await this.remoteLoadSubscription.run(authToken);
      const { status, id, endDate, gracePeriodEndDate } = userSubscription.subscription;
      const { payment } = userSubscription.subscription;
      const userId = userSubscription.subscription.id_usuario || userSubscription.userId;

      const formattedEndDate = gracePeriodEndDate
        ? new Intl.DateTimeFormat('pt-br', { day: '2-digit', month: '2-digit' }).format(new Date(gracePeriodEndDate))
        : '';

      this.setAvailableForTrialStorage(userSubscription?.subscription?.trial);

      return {
        status,
        gracePeriodEndDate: formattedEndDate,
        isPending: status === StatusSubscription.Pending,
        isPremium: !!(userSubscription.subscription.active || userSubscription.freeTasting.active),
        id,
        userId,
        paymentMethod: payment?.method,
        paidValue: payment?.paid?.formattedValue,
        expirationDate: endDate,
        installmentsQuantity: payment?.installment.quantity,
        installmentValue: payment?.installment?.formatted.value,
        gatewayName: payment?.gateway?.name,
        period: userSubscription?.subscription?.plan?.period,
      };
    } catch (error) {
      return handleError(
        error,
        'useCaseCheckSubscription',
        [],
        'Ops, parece que não conseguimos recuperar os dados de assinatura',
      );
    }
  }

  setAvailableForTrialStorage(availableForTrial: boolean) {
    SubscriptionLocalStorage.setAvailableForTrial(availableForTrial);
  }

  availableForTrial(subscriptions: SubscriptionModel[]) {
    if (!subscriptions.length) return true;

    const isIuguGateway = subscriptions.find((subscription) => subscription.payment.gateway.name === 'iugu');
    const isFreeCoupon = subscriptions.find((subscription) => subscription.payment.gateway.name === 'pebmed');

    if (isIuguGateway) return false;

    return !!isFreeCoupon;
  }
}

export default new UseCaseCheckSubscription(RemoteLoadSubscription);
